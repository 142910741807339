/* status.css */

.status-container {
  max-width: 100vw;
  margin-top: -5rem;
  padding: 20px;
  padding-bottom: 5rem;
  text-align: center;
  background-color: rgba(5, 4, 18, 1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.status-container h1 {
  margin-top: 5rem;
  color: white;
  margin-bottom: 20px;
  font-size: 36px;
}

.status-container iframe {
  border: none;
  width: 100%;
  height: 850px;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .status-container {
    padding: 15px;
    padding-bottom: 4rem; /* Reduce bottom padding for smaller screens */
  }

  .status-container h1 {
    margin-top: 3rem; /* Reduce top margin for smaller screens */
    font-size: 30px; /* Reduce font size */
  }

  .status-container iframe {
    height: 600px; /* Adjust iframe height for tablets and smaller screens */
  }
}

@media (max-width: 768px) {
  .status-container {
    padding: 10px;
    padding-bottom: 3rem; /* Further reduce bottom padding */
  }

  .status-container h1 {
    margin-top: 2rem; /* Further reduce top margin */
    font-size: 24px; /* Reduce font size for better fit */
  }

  .status-container iframe {
    height: 400px; /* Adjust iframe height for mobile devices */
  }
}

@media (max-width: 480px) {
  .status-container {
    padding: 5px;
    padding-bottom: 2rem; /* Minimal bottom padding for very small screens */
    margin-top: -2rem; /* Adjust margin-top for very small screens */
  }

  .status-container h1 {
    margin-top: 1rem; /* Minimal top margin */
    font-size: 20px; /* Further reduce font size */
  }

  .status-container iframe {
    height: 720px; /* Further reduce iframe height for very small screens */
    max-width: fit-content;
    overflow-y: hidden;
  }
}
