.DiscordPackagesBanner {
  background-color: rgba(5, 4, 18 1);
}

.PackageContainer {
  display: flex;
  justify-content: space-around;
  padding: 4rem 8rem 4rem 8rem;
  background-color: rgba(5, 4, 18, 1);
  min-height: 30vh;
}

@media (max-width: 1024px) {
  .PackageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Stack items vertically on smaller screens */
    padding: 6rem 4rem; /* Reduce padding for tablets and smaller desktops */
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items horizontally */
  }
}

@media (max-width: 768px) {
  .PackageContainer {
    display: flex;
    padding: 13rem 2rem 2rem 2rem; /* Further reduce padding for mobile devices */
  }
}

@media (max-width: 480px) {
  .PackageContainer {
    padding: 15rem 1rem 1rem 1rem; /* Minimal padding for very small screens */
    min-height: 20vh; /* Reduce minimum height for very small screens */
  }

  .DiscordPackagesBanner {
    padding: 1rem; /* Adjust padding for the banner on very small screens */
  }
}
