/* Styling voor de footer */
.footer {
  background-color: rgba(5, 4, 18, 1);
  color: white; /* Witte tekstkleur */
  text-align: center; /* Centraal uitlijnen van de tekst */
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 6rem;
  z-index: 10;
}

.footer-content {
  padding: 0rem 10rem 0rem 10rem;
  display: flex; /* Flexbox gebruiken om de inhoud te verdelen */
  justify-content: space-between; /* Verdeel ruimte gelijkmatig tussen de linker- en rechterkant */
  align-items: center;
  margin: 0 auto; /* Centreer de footer inhoud */
}

.footer-left {
  flex: 1; /* Laat de linkerzijde de resterende ruimte innemen */
}

.footer-left h2 {
  margin: 0;
  font-size: 1.5rem; /* Vergroot het lettertype voor de bedrijfsnaam */
}

.footer-left p {
  margin: 5px 0 0; /* Ruimte boven en onder de copyright tekst */
  font-size: 0.875rem; /* Kleinere tekstgrootte voor copyright */
}

.footer-right {
  flex: 2; /* Laat de rechterzijde meer ruimte innemen */
  text-align: right; /* Tekst rechts uitlijnen */
}

.footer-right ul {
  list-style: none; /* Verwijder standaard lijstenstijl */
  padding: 0;
  margin: 0;
}

.footer-right li {
  display: inline; /* Weergave als inline-items */
  margin-left: 15px; /* Ruimte tussen de lijstitems */
}

.footer-right a {
  color: #007bff; /* Blauwe kleur voor links */
  text-decoration: none; /* Verwijder onderlijn van links */
}

.footer-right a:hover {
  text-decoration: underline; /* Onderlijn tonen bij hover */
}

.social-media {
  margin-top: 10px; /* Ruimte boven de sociale media links */
}

.social-media a {
  color: #007bff; /* Blauwe kleur voor sociale media links */
  margin-left: 10px; /* Ruimte tussen sociale media links */
  text-decoration: none; /* Verwijder onderlijn van links */
}

.social-media a:hover {
  text-decoration: underline; /* Onderlijn tonen bij hover */
}

@media (max-width: 1024px) {
  .footer-content {
    padding: 0rem 5rem; /* Reduce padding for tablets and smaller desktops */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    overflow: hidden;
  }
  .footer-content {
    padding: 0rem 5rem; /* Reduce padding for tablets and smaller desktops */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .footer-left,
  .footer-right {
    text-align: center; /* Center align text on smaller screens */
    margin-bottom: 1rem; /* Add bottom margin to separate sections */
  }

  .footer-right {
    flex: none; /* Allow content to flow naturally */
  }
}

@media (max-width: 768px) {
  .footer-content {
    padding: 0rem 2rem; /* Further reduce padding for mobile devices */
  }

  .footer-left h2 {
    font-size: 1.25rem; /* Reduce font size for smaller screens */
  }

  .footer-left p {
    font-size: 0.75rem; /* Further reduce font size for smaller screens */
  }

  .footer-right ul {
    margin-top: 1rem; /* Add space above the list for better readability */
  }

  .footer-right li {
    display: block; /* Stack list items vertically */
    margin-left: 0; /* Remove left margin */
    margin-bottom: 0.5rem; /* Add bottom margin for spacing */
  }
}

@media (max-width: 480px) {
  .footer-content {
    padding: 0rem 1rem; /* Minimal padding for very small screens */
  }

  .footer-left h2 {
    font-size: 1rem; /* Further reduce font size for very small screens */
  }

  .footer-left p {
    font-size: 0.625rem; /* Further reduce font size */
  }

  .footer-right ul {
    margin-top: 0.5rem; /* Minimal space above the list */
  }

  .social-media {
    margin-top: 5px; /* Minimal margin for social media links */
  }
}
