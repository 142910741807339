.pricing-card {
  background-color: rgba(41, 93, 148, 0.4);
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 16rem;
  height: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: scale(1.1);
  box-shadow: 0 0 2rem rgba(94, 172, 255, 0.7);
}

.pricing-card h2 {
  font-size: 24px;
  margin-bottom: 2rem;
}

.pricing-card p {
  font-size: 1rem;
  margin-bottom: 0rem;
  line-height: 0.3rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0 1rem 0;
}

.pricing-card ul li {
  margin: 0.3rem 0;
}

.pricing-card .price {
  font-size: 24px;
  margin-bottom: 15px;
}

.pricing-card a {
  background-color: #0069d9;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-card button:hover {
  background-color: #0056b3;
}

@media (max-width: 1024px) {
  .pricing-card {
    width: 14rem; /* Adjust width for tablets and smaller screens */
    margin: 2rem 3rem 2rem 3rem;
  }

  .pricing-card h2 {
    font-size: 22px; /* Reduce font size slightly for smaller screens */
  }

  .pricing-card .price {
    font-size: 22px; /* Adjust font size for the price */
  }
}

@media (max-width: 768px) {
  .pricing-card {
    width: 18rem; /* Further adjust width for mobile devices */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .pricing-card h2 {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  .pricing-card p {
    font-size: 0.9rem; /* Adjust font size for better readability */
    line-height: 1.5rem; /* Increase line-height for better readability */
  }

  .pricing-card .price {
    font-size: 20px; /* Adjust font size for the price */
  }

  .pricing-card a {
    padding: 8px 16px; /* Adjust padding for buttons on mobile devices */
  }
}

@media (max-width: 480px) {
  .pricing-card {
    width: 80%; /* Make cards full width on very small screens */
    padding: 10px; /* Adjust padding for very small screens */
    margin: 2rem 3rem 2rem 3rem;
  }

  .pricing-card h2 {
    font-size: 18px; /* Further reduce font size for very small screens */
  }

  .pricing-card p {
    font-size: 0.8rem; /* Further adjust font size for very small screens */
    line-height: 1.6rem; /* Adjust line-height for better readability */
  }

  .pricing-card .price {
    font-size: 18px; /* Further adjust font size for the price */
  }

  .pricing-card a {
    padding: 8px 14px; /* Adjust padding for very small screens */
  }
}
