/* Existing header CSS */
.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 5rem;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  color: white;
  box-sizing: border-box;
  padding: 0rem 10rem 0rem 10rem;
  overflow: hidden;
}

.HeaderLogo {
  text-decoration: none;
  color: white;
}

.header h1 {
  margin: 0;
  font-size: 2rem;
}

.header nav {
  display: flex;
  gap: 10px;
}

.header-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.login-button {
  background-color: #0069d9;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background-color: white;
  color: black;
}

/* Dropdown menu styles */
.header-nav {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  padding: 10px 0;
  color: black;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Ensure the Products button stays white while dropdown is visible */
.header-nav:hover .header-button {
  background-color: white;
  color: black;
}

.header-nav:hover .dropdown-content {
  display: block;
}

@media (max-width: 1024px) {
  .header {
    padding: 0rem 5rem 0rem 5rem;
  }

  .header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .header {
    background-color: rgba(5, 4, 18, 1);
    position: relative;
    flex-direction: column;
    height: auto;
    padding: 1rem;
    align-items: flex-start;
    max-width: 100vw;
  }

  .header h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .header nav {
    display: none;
    flex-direction: column;
    width: 70%;
    padding: 2rem;
    gap: 5px;
  }

  .header-button {
    width: 100%;
    padding: 10px;
    text-align: center;
    border: 0.1rem solid white;
  }

  .login-button {
    width: 100%;
    padding: 10px;
  }

  .header-nav {
    width: 100%;
    padding: 10px 0;
    display: none;
  }

  .dropdown-content {
    position: relative;
    min-width: 100vw;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0.5rem;
  }

  .header h1 {
    font-size: 1.2rem;
  }

  .header-button,
  .login-button {
    font-size: 0.9rem;
    padding: 8px 15px;
    max-width: 100%;
  }
}
/* Add these styles to your existing CSS */

/* Styles for hamburger menu */
.hamburger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  transition: 0.3s ease;
}

/* Default nav menu styles */
.nav-menu {
  display: flex;
  gap: 10px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
    margin-left: auto; /* Pushes the hamburger menu to the right side */
  }

  /* Hide the default nav menu on mobile */
  .header nav {
    display: none;
  }

  .headergrid {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Distribute space between items */
    align-items: center; /* Center items vertically if the height is set */
  }

  /* Style and position the mobile navigation menu */
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 250px; /* Adjust the width as needed */
    background-color: rgba(0, 0, 0, 1);
    padding: 1rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 20;
    margin: 1rem 1rem 1rem 1rem;
  }

  .header-nav-mobile {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }

  .nav-menu.active {
    display: flex;
    transform: translateX(0); /* Slide in when active */
  }

  .nav-menu a {
    padding: 10px;
    color: white;
    text-decoration: none;
  }

  .nav-menu a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
