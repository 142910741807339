.game-panel {
  display: flex;
  background-color: rgba(5, 4, 18, 1);
  color: #fff;
  padding: 20px;
  padding: 4rem 5rem 4rem 13rem;
}

.left-section {
  flex: 1;
  padding-right: 20px;
}

.left-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.left-section p {
  margin-bottom: 20px;
}

.options-list {
  list-style: none;
  padding: 0;
}

.options-list li {
  margin-bottom: 10px;
  font-size: 16px;
}

.right-section {
  flex: 1.5;
  padding: 1em 0px 1rem 0px;
  border-radius: 8px;
}

.right-section-img-gamepanel {
  width: auto;
  height: 50vh;
  border-radius: 8px;
}

.header-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.header-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.start-btn {
  background-color: #28a745;
}

.restart-btn {
  background-color: #ffc107;
}

.stop-btn {
  background-color: #dc3545;
}

.status-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.status-item {
  flex: 1;
  text-align: center;
}

.status-item h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.status-item p {
  font-size: 20px;
}

.console {
  background-color: #333;
  padding: 15px;
  border-radius: 4px;
  height: 200px;
  overflow-y: auto;
}

.console p {
  font-family: monospace;
  color: #ccc;
}

@media (max-width: 1024px) {
  .game-panel {
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 2rem;
  }

  .left-section {
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .right-section {
    padding: 1rem;
  }

  .right-section-img-gamepanel {
    height: 35vh; /* Adjust height for smaller screens */
  }

  .header-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .header-buttons button {
    width: 100%;
  }

  .status-info {
    flex-direction: column;
  }

  .status-item {
    margin-bottom: 1rem;
  }

  .console {
    height: 150px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 768px) {
  .game-panel {
    padding: 1rem;
  }

  .right-section-img-gamepanel {
    height: 30vh; /* Further adjust height for mobile devices */
  }

  .header-buttons {
    gap: 5px;
  }

  .console {
    height: 120px; /* Adjust height for mobile devices */
  }
}

@media (max-width: 620px) {
  .right-section-img-gamepanel {
    height: 22vh; /* Further adjust height for very small screens */
    display: none;
  }
}

@media (max-width: 480px) {
  .game-panel {
    padding: 2rem;
  }

  .right-section-img-gamepanel {
    height: 22vh; /* Further adjust height for very small screens */
    display: none;
  }

  .header-buttons button {
    padding: 8px 16px;
  }

  .console {
    height: 100px; /* Minimal height for very small screens */
  }
}
