/* Zorg ervoor dat je header een lagere z-index heeft dan de .HeroContainer */
.Header {
  position: relative; /* Of fixed als je wilt dat de header altijd zichtbaar blijft */
  z-index: 1; /* Lager dan .HeroContainer */
}

/* HeroContainer bovenaan zetten */
.HeroContainer {
  position: relative;
  width: 100%; /* Zorg ervoor dat het de volledige breedte van de viewport gebruikt */
  height: auto;
  margin: 0;
  z-index: 2; /* Hoger dan de header */
  margin-top: -5rem;
}

.HeroBanner {
  width: 100%;
  height: auto;
  display: block;
}

#BannerColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparante overlay */

  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box; /* Zorgt ervoor dat padding wordt meegerekend in de hoogte en breedte */
}

.HeroContent {
  position: relative;
  z-index: 2;
  max-width: 100vw;
  padding: 25vh 15rem 25vh 15rem;
}

.HeroContent h1 {
  font-size: 3rem;
  margin: 0;
  color: #007bff; /* Blauwe kleur zoals in het voorbeeld */
  text-transform: uppercase;
  font-weight: bold;
}

.HeroContent h2 {
  font-size: 2rem;
  margin: 10px 0;
  font-weight: bold;
}

.HeroContent p {
  font-size: 1rem;
  margin: 10px 0;
}

.HeroContent ul {
  list-style: none;
  padding: 0;
}

.HeroContent li {
  font-size: 1rem;
  margin: 5px 0;
}

@media (max-width: 1024px) {
  .Header {
    z-index: 1; /* Lager dan .HeroContainer */
    padding: 1rem; /* Verminder padding indien nodig */
  }

  .HeroContainer {
    margin-top: -5rem; /* Pas margin-top aan voor kleinere schermen */
  }

  .HeroContent {
    padding: 10vh 5rem 20vh 5rem; /* Verminder padding voor kleinere schermen */
  }

  .HeroContent h1 {
    font-size: 2.5rem; /* Pas font-size aan voor kleinere schermen */
  }

  .HeroContent h2 {
    font-size: 1.5rem; /* Pas font-size aan voor kleinere schermen */
  }

  .HeroContent p {
    font-size: 0.9rem; /* Pas font-size aan voor kleinere schermen */
  }
}

/* For mobile devices */
@media (max-width: 889px) {
  .Header {
    z-index: 1; /* Lager dan .HeroContainer */
    padding: 0.5rem; /* Nogmaals, verminder padding indien nodig */
  }

  .HeroContainer {
    margin-top: -5rem; /* Pas margin-top aan voor mobiele schermen */
  }

  .HeroContent {
    padding: 15vh 2rem 15vh 2rem; /* Verminder padding verder voor mobiele schermen */
  }

  .HeroContent h1 {
    font-size: 2rem; /* Pas font-size aan voor mobiele schermen */
  }

  .HeroContent h2 {
    font-size: 1.25rem; /* Pas font-size aan voor mobiele schermen */
  }

  .HeroContent p {
    font-size: 0.8rem; /* Pas font-size aan voor mobiele schermen */
  }

  .HeroContent ul {
    padding: 0 1rem; /* Voeg wat padding toe voor lijst-items */
  }

  .HeroContent li {
    font-size: 0.8rem; /* Pas font-size aan voor mobiele schermen */
  }
}

/* For very small devices (portrait phones) */
@media (max-width: 480px) {
  .Header {
    padding: 0.25rem; /* Minimaal padding voor mobiele schermen */
  }

  .HeroContainer {
    margin-top: 0rem; /* Pas margin-top aan voor zeer kleine schermen */
  }

  .HeroContent {
    padding: 10vh 1rem 10vh 1rem; /* Nog verder verminderde padding voor zeer kleine schermen */
  }

  .HeroContent h1 {
    font-size: 1.5rem; /* Pas font-size aan voor zeer kleine schermen */
  }

  .HeroContent h2 {
    font-size: 1rem; /* Pas font-size aan voor zeer kleine schermen */
  }

  .HeroContent p {
    font-size: 0.75rem; /* Pas font-size aan voor zeer kleine schermen */
  }

  .HeroContent ul {
    padding: 0 0.5rem; /* Nog minder padding voor lijst-items */
  }

  .HeroContent li {
    font-size: 0.75rem; /* Pas font-size aan voor zeer kleine schermen */
  }
}
