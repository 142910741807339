/* status.css */

.four0four-container {
  max-width: 100vw;
  height: 100vh;
  margin-top: -5rem;
  padding: 20px;
  padding-bottom: 5rem;
  text-align: center;
  background-color: rgba(5, 4, 18, 1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  color: white;
}

.status-container h1 {
  margin-top: 5rem;
  color: white;
  margin-bottom: 20px;
  font-size: 36px;
}

.notfoundh1 {
  margin: 10rem 0rem 2rem 0rem;
}

.status-container iframe {
  border: none;
  width: 100%;
  height: 850px;
  border-radius: 10px;
}
